define("plutof/components/dmp/view/costs", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::ItemList
      @collapse={{not @costs.length}}
      as |panel|
  >
      <panel.header
          @title={{i18n-t "dmp.panels.cost"}}
          @count={{@costs.length}} />
  
      <panel.content>
          <div class="table-responsive">
              <table class="data-table">
                  <thead>
                      <tr>
                          <th><GuidedView @path="dmp.cost.title" /></th>
                          <th><GuidedView @path="dmp.cost.description" /></th>
                          <th><GuidedView @path="dmp.cost.value" /></th>
                          <th><GuidedView @path="dmp.cost.currency" /></th>
                      </tr>
                  </thead>
  
                  <tbody>
                      {{#each @costs as |cost|}}
                          <tr>
                              <td>{{cost.title}}</td>
                              <td>{{cost.description}}</td>
                              <td>{{cost.value}}</td>
                              <td>{{cost.currency_code}}</td>
                          </tr>
                      {{/each}}
                  </tbody>
              </table>
          </div>
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "au0F956a",
    "block": "[[[8,[39,0],null,[[\"@collapse\"],[[28,[37,1],[[30,1,[\"length\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,2],[\"dmp.panels.cost\"],null],[30,1,[\"length\"]]]],null],[1,\"\\n\\n    \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"table-responsive\"],[12],[1,\"\\n            \"],[10,\"table\"],[14,0,\"data-table\"],[12],[1,\"\\n                \"],[10,\"thead\"],[12],[1,\"\\n                    \"],[10,\"tr\"],[12],[1,\"\\n                        \"],[10,\"th\"],[12],[8,[39,3],null,[[\"@path\"],[\"dmp.cost.title\"]],null],[13],[1,\"\\n                        \"],[10,\"th\"],[12],[8,[39,3],null,[[\"@path\"],[\"dmp.cost.description\"]],null],[13],[1,\"\\n                        \"],[10,\"th\"],[12],[8,[39,3],null,[[\"@path\"],[\"dmp.cost.value\"]],null],[13],[1,\"\\n                        \"],[10,\"th\"],[12],[8,[39,3],null,[[\"@path\"],[\"dmp.cost.currency\"]],null],[13],[1,\"\\n                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n\\n                \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,1]],null]],null],null,[[[1,\"                        \"],[10,\"tr\"],[12],[1,\"\\n                            \"],[10,\"td\"],[12],[1,[30,3,[\"title\"]]],[13],[1,\"\\n                            \"],[10,\"td\"],[12],[1,[30,3,[\"description\"]]],[13],[1,\"\\n                            \"],[10,\"td\"],[12],[1,[30,3,[\"value\"]]],[13],[1,\"\\n                            \"],[10,\"td\"],[12],[1,[30,3,[\"currency_code\"]]],[13],[1,\"\\n                        \"],[13],[1,\"\\n\"]],[3]],null],[1,\"                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@costs\",\"panel\",\"cost\"],false,[\"plutof-panel/item-list\",\"not\",\"i18n-t\",\"guided-view\",\"each\",\"-track-array\"]]",
    "moduleName": "plutof/components/dmp/view/costs.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});