define("plutof/components/dmp/-distribution/license", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AddMultipleForm @objects={{@licenses}} as |adder|>
      <div class="add-component">
          <adder.column @path="dmp.licence.ref">
              <PlutofSelect::Value
                  @content={{this.licenseChoices}}
                  @value={{this.ref}}
                  @selectClass="form-control control" />
          </adder.column>
  
          <adder.column @path="dmp.licence.start_date">
              <DatePicker
                  @value={{this.startDate}}
                  @pickerClass="form-control control" />
          </adder.column>
  
          <adder.addButton
              @add={{this.add}}
              @disable={{not this.canAdd}} />
      </div>
  
      <Common::RecordList
          @records={{@licenses}}
          @remove={{adder.remove}}
          as |license|
      >
          {{license.license_ref}} - {{license.start_date}}
      </Common::RecordList>
  </AddMultipleForm>
  
  */
  {
    "id": "qxPMaYOd",
    "block": "[[[8,[39,0],null,[[\"@objects\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"add-component\"],[12],[1,\"\\n        \"],[8,[30,2,[\"column\"]],null,[[\"@path\"],[\"dmp.licence.ref\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,1],null,[[\"@content\",\"@value\",\"@selectClass\"],[[30,0,[\"licenseChoices\"]],[30,0,[\"ref\"]],\"form-control control\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2,[\"column\"]],null,[[\"@path\"],[\"dmp.licence.start_date\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,2],null,[[\"@value\",\"@pickerClass\"],[[30,0,[\"startDate\"]],\"form-control control\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2,[\"addButton\"]],null,[[\"@add\",\"@disable\"],[[30,0,[\"add\"]],[28,[37,3],[[30,0,[\"canAdd\"]]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,4],null,[[\"@records\",\"@remove\"],[[30,1],[30,2,[\"remove\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"license_ref\"]]],[1,\" - \"],[1,[30,3,[\"start_date\"]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@licenses\",\"adder\",\"license\"],false,[\"add-multiple-form\",\"plutof-select/value\",\"date-picker\",\"not\",\"common/record-list\"]]",
    "moduleName": "plutof/components/dmp/-distribution/license.hbs",
    "isStrictMode": false
  });
  const LICENCES = [{
    name: 'Attribution-NonCommercial-NoDerivs (CC BY-NC-ND)',
    url: 'https://creativecommons.org/licenses/by-nc-nd/4.0/'
  }, {
    name: 'Attribution-NonCommercial-ShareAlike (CC BY-NC-SA)',
    url: 'https://creativecommons.org/licenses/by-nc-sa/4.0/'
  }, {
    name: 'Attribution-NonCommercial (CC BY-NC)',
    url: 'https://creativecommons.org/licenses/by-nc/4.0/'
  }, {
    name: 'Attribution-NoDerivs (CC BY-ND)',
    url: 'https://creativecommons.org/licenses/by-nd/4.0/'
  }, {
    name: 'Attribution-ShareAlike (CC BY-SA)',
    url: 'https://creativecommons.org/licenses/by-sa/4.0/'
  }, {
    name: 'Attribution (CC BY)',
    url: 'https://creativecommons.org/licenses/by/4.0/'
  }];
  let DMPDistributionLicense = _exports.default = (_dec = (0, _object.computed)('ref', 'startDate'), _class = class DMPDistributionLicense extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ref", _descriptor, this);
      _initializerDefineProperty(this, "startDate", _descriptor2, this);
    }
    get licenseChoices() {
      return LICENCES.map(licence => {
        return {
          value: licence.url,
          display_name: `${licence.name} - ${licence.url}`
        };
      });
    }
    get canAdd() {
      return this.ref && this.startDate;
    }
    add() {
      this.args.licenses.pushObject({
        license_ref: this.ref,
        start_date: this.startDate
      });
      this.ref = null;
      this.startDate = null;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ref", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "licenseChoices", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "licenseChoices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canAdd", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "canAdd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DMPDistributionLicense);
});