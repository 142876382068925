define("plutof/components/plutof-panel/-panel/button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PromiseButton
      @clicked={{@clicked}}
      @disabled={{@disabled}}
      @restIcon={{@icon}}
      class="plutof-btn__controls plutof-btn__controls--xs {{@class}}"
      ...attributes
  >
      {{yield}}
  </PromiseButton>
  
  */
  {
    "id": "r7fM2ROz",
    "block": "[[[8,[39,0],[[16,0,[29,[\"plutof-btn__controls plutof-btn__controls--xs \",[30,1]]]],[17,2]],[[\"@clicked\",\"@disabled\",\"@restIcon\"],[[30,3],[30,4],[30,5]]],[[\"default\"],[[[[1,\"\\n    \"],[18,6,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@clicked\",\"@disabled\",\"@icon\",\"&default\"],false,[\"promise-button\",\"yield\"]]",
    "moduleName": "plutof/components/plutof-panel/-panel/button.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});