define("plutof/components/dmp/view/contact", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "dmp.panels.contact"}}>
      <Layout::RegularGrid @columns={{2}}>
          <GuidedView @path="dmp.contact.name">
              {{@contact.name}}
          </GuidedView>
  
          <GuidedView @path="dmp.contact.mbox">
              {{@contact.mbox}}
          </GuidedView>
  
          <GuidedView @path="dmp.contact.identifier">
              {{@contact.contact_id.identifier}}
          </GuidedView>
  
          <GuidedView @path="dmp.contact.identifierType">
              {{@contact.contact_id.type}}
          </GuidedView>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "Tm7FVS6z",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"dmp.panels.contact\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.contact.name\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.contact.mbox\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"mbox\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.contact.identifier\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"contact_id\",\"identifier\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.contact.identifierType\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"contact_id\",\"type\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@contact\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"guided-view\"]]",
    "moduleName": "plutof/components/dmp/view/contact.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});