define("plutof/components/dmp/project-funding", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object/computed", "plutof/utils/validations"], function (_exports, _component, _templateFactory, _component2, _computed, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Layout::RegularGrid @columns={{2}}>
      <GuidedForm @path="dmp.project.funding.status" class={{unless this.fundingStatusIsValid "has-error"}}>
          <PlutofSelect::Direct
              @content={{this.FUNDING_STATUSES}}
              @value={{@funding.funding_status}} />
      </GuidedForm>
  </Layout::RegularGrid>
  
  <Layout::RegularGrid @columns={{2}}>
      <GuidedForm @path="dmp.project.funding.funder.identifier" class={{unless this.funderIdIsValid "has-error"}}>
          <Input
              @type="text"
              @value={{@funding.funder_id.identifier}}
              @disabled={{this.disableFunding}}
              @class="form-control" />
      </GuidedForm>
  
      <GuidedForm @path="dmp.project.funding.funder.type" class={{unless this.funderTypeIsValid "has-error"}}>
          <PlutofSelect::Direct
              @content={{this.FUNDER_TYPES}}
              @value={{@funding.funder_id.type}}
              @disabled={{this.disableFunding}} />
      </GuidedForm>
  
      <GuidedForm @path="dmp.project.funding.grant.identifier">
          <Input
              @type="text"
              @value={{@funding.grant_id.identifier}}
              @disabled={{this.disableFunding}}
              @class="form-control" />
      </GuidedForm>
  
      <GuidedForm @path="dmp.project.funding.grant.type">
          <PlutofSelect::Direct
              @content={{this.GRANT_TYPES}}
              @value={{@funding.grant_id.type}}
              @disabled={{this.disableFunding}} />
      </GuidedForm>
  </Layout::RegularGrid>
  
  */
  {
    "id": "8a1P45yB",
    "block": "[[[8,[39,0],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[16,0,[52,[51,[30,0,[\"fundingStatusIsValid\"]]],\"has-error\"]]],[[\"@path\"],[\"dmp.project.funding.status\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@content\",\"@value\"],[[30,0,[\"FUNDING_STATUSES\"]],[30,1,[\"funding_status\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[16,0,[52,[51,[30,0,[\"funderIdIsValid\"]]],\"has-error\"]]],[[\"@path\"],[\"dmp.project.funding.funder.identifier\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@type\",\"@value\",\"@disabled\",\"@class\"],[\"text\",[30,1,[\"funder_id\",\"identifier\"]],[30,0,[\"disableFunding\"]],\"form-control\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,1],[[16,0,[52,[51,[30,0,[\"funderTypeIsValid\"]]],\"has-error\"]]],[[\"@path\"],[\"dmp.project.funding.funder.type\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@content\",\"@value\",\"@disabled\"],[[30,0,[\"FUNDER_TYPES\"]],[30,1,[\"funder_id\",\"type\"]],[30,0,[\"disableFunding\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@path\"],[\"dmp.project.funding.grant.identifier\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@type\",\"@value\",\"@disabled\",\"@class\"],[\"text\",[30,1,[\"grant_id\",\"identifier\"]],[30,0,[\"disableFunding\"]],\"form-control\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@path\"],[\"dmp.project.funding.grant.type\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@content\",\"@value\",\"@disabled\"],[[30,0,[\"GRANT_TYPES\"]],[30,1,[\"grant_id\",\"type\"]],[30,0,[\"disableFunding\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@funding\"],false,[\"layout/regular-grid\",\"guided-form\",\"unless\",\"plutof-select/direct\",\"input\"]]",
    "moduleName": "plutof/components/dmp/project-funding.hbs",
    "isStrictMode": false
  });
  let DmpProjectFunding = _exports.default = (_dec = (0, _computed.notEmpty)('args.funding.funding_status'), _dec2 = (0, _computed.notEmpty)('args.funding.funder_id.identifier'), _dec3 = (0, _computed.notEmpty)('args.funding.funder_id.type'), _class = class DmpProjectFunding extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "GRANT_TYPES", ['url', 'other']);
      _defineProperty(this, "FUNDER_TYPES", ['fundref', 'url', 'other']);
      _defineProperty(this, "FUNDING_STATUSES", ['planned', 'applied', 'granted', 'rejected']);
      _initializerDefineProperty(this, "fundingStatusIsValid", _descriptor, this);
      _initializerDefineProperty(this, "funderIdIsValid", _descriptor2, this);
      _initializerDefineProperty(this, "funderTypeIsValid", _descriptor3, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "fundingStatusIsValid", [_validations.validator, _dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "funderIdIsValid", [_validations.validator, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "funderTypeIsValid", [_validations.validator, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DmpProjectFunding);
});