define("plutof/controllers/dmp/edit", ["exports", "@ember/controller", "@ember/object", "plutof/mixins/edit-controller", "plutof/utils/notifications", "moment"], function (_exports, _controller, _object, _editController, _notifications, moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function trimValue(key, value) {
    if (typeof value === 'string') {
      const trimmed = value.trim();
      return trimmed === '' ? undefined : trimmed;
    }
    return value;
  }

  // XXX Hack for controlling fadeId property in data.
  // TODO: Refactor fade elements.
  function purgeFadeId(object) {
    const keys = Object.keys(object);
    keys.forEach(key => {
      const value = object[key];
      if (value && typeof value === 'object') {
        purgeFadeId(value);
      } else if (value instanceof Array) {
        value.forEach(purgeFadeId);
      } else if (key === 'fadeId') {
        delete object[key];
      }
    });
  }
  function formatDmpToSchema(dmp) {
    return {
      dmp: {
        ...dmp,
        contributor: dmp.contributor?.map(contributor => {
          return {
            ...contributor,
            role: contributor.role.split(',')
          };
        }),
        cost: dmp.cost?.map(cost => {
          const value = parseFloat(cost.value);
          return {
            ...cost,
            // API doesn't accept null (which NaN is coerced to)
            value: isNaN(value) ? undefined : value
          };
        }),
        // funding.grant_id is optional, but we have to bind its fields to inputs.
        // Then, if those remain empty, we have to do this.
        // The inverse must be done in the edit route
        project: dmp.project.map(project => ({
          ...project,
          funding: project.funding.map(funding => ({
            ...funding,
            grant_id: funding.grant_id.identifier ? funding.grant_id : undefined
          }))
        }))
      }
    };
  }
  let DMPEditController = (_class = class DMPEditController extends _controller.default.extend(_editController.default) {
    async save() {
      await this.model.record.save();
      await this.model.accessRights.save();
    }
    saveDmp(formDmp) {
      (0, _notifications.clearNotifications)();
      (0, _notifications.displayNotification)('status', this.i18n.t('General.saving'));

      // XXX XXX XXX Sad but necessary.
      purgeFadeId(formDmp);
      const dmp = formatDmpToSchema(formDmp);

      // Trim strings and remove empty string values from the DMP object.
      const trimmedDmp = JSON.parse(JSON.stringify(dmp, trimValue));
      console.log('TRIMMED DMP', trimmedDmp);

      // TODO: Consider adding time to it.
      const currentDate = moment().format('YYYY-MM-DD');

      // TODO: Make it user-editable.
      trimmedDmp.dmp.created = trimmedDmp.dmp.created || currentDate;
      trimmedDmp.dmp.modified = currentDate;
      this.set('model.record.dmp', trimmedDmp);
      return this.save().then(() => {
        (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
        this.set('routeHasBeenLoaded', false);
        this.routeActions.goBack();
      }).catch(_notifications.reportError);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "saveDmp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveDmp"), _class.prototype), _class);
  var _default = _exports.default = DMPEditController;
});