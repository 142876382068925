define("plutof/components/dmp/cost", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/object/computed", "plutof/utils/formatters", "@ember/utils", "plutof/utils/validations"], function (_exports, _component, _templateFactory, _component2, _object, _computed, _formatters, _utils, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
      <td class={{unless this.titleIsValid "has-error"}}>
          <Input @type="text" @value={{@cost.title}} @class="mini-input" />
      </td>
  
      <td>
          <FlexibleTextarea @value={{@cost.description}} @class="mini-input" />
      </td>
  
      <td class={{unless this.valueIsValid "has-error"}}>
          <Input @type="text" @value={{@cost.value}} @class="mini-input" />
      </td>
  
      <td>
          <PlutofSelect::Direct
              @content={{this.currencies}}
              @value={{@cost.currency_code}}
              @selectClass="mini-input" />
      </td>
  
      <td class="align-right">
          <button
              {{on "click" (fn @remove @cost)}}
              class="btn btn-link data-table-btn"
          >
              <span class={{icon "remove"}}></span>
          </button>
      </td>
  </tr>
  
  */
  {
    "id": "pNHictaJ",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n    \"],[10,\"td\"],[15,0,[52,[51,[30,0,[\"titleIsValid\"]]],\"has-error\"]],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@type\",\"@value\",\"@class\"],[\"text\",[30,1,[\"title\"]],\"mini-input\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@value\",\"@class\"],[[30,1,[\"description\"]],\"mini-input\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[15,0,[52,[51,[30,0,[\"valueIsValid\"]]],\"has-error\"]],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@type\",\"@value\",\"@class\"],[\"text\",[30,1,[\"value\"]],\"mini-input\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@content\",\"@value\",\"@selectClass\"],[[30,0,[\"currencies\"]],[30,1,[\"currency_code\"]],\"mini-input\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[14,0,\"align-right\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,4],[\"click\",[28,[37,5],[[30,2],[30,1]],null]],null],[12],[1,\"\\n            \"],[10,1],[15,0,[28,[37,6],[\"remove\"],null]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@cost\",\"@remove\"],false,[\"unless\",\"input\",\"flexible-textarea\",\"plutof-select/direct\",\"on\",\"fn\",\"icon\"]]",
    "moduleName": "plutof/components/dmp/cost.hbs",
    "isStrictMode": false
  });
  let DmpCost = _exports.default = (_dec = (0, _computed.notEmpty)('args.cost.title'), _dec2 = (0, _object.computed)('args.cost.value'), _dec3 = (0, _computed.notEmpty)('args.cost.currency'), _class = class DmpCost extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "currencies", ['EUR', 'USD']);
      _initializerDefineProperty(this, "titleIsValid", _descriptor, this);
      _initializerDefineProperty(this, "currencyIsValid", _descriptor2, this);
    }
    get valueIsValid() {
      const value = this.args.cost.value;
      if ((0, _utils.isPresent)(value)) {
        return _formatters.INT_REGEX.test(value);
      }
      return true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "titleIsValid", [_validations.validator, _dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "valueIsValid", [_validations.validator, _dec2], Object.getOwnPropertyDescriptor(_class.prototype, "valueIsValid"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currencyIsValid", [_validations.validator, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DmpCost);
});