define("plutof/components/validation/-group/part", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
      this.context
      (hash split=(component "validation/split-context" context=this.context))}}
  
  */
  {
    "id": "HKgRQ5sH",
    "block": "[[[18,1,[[30,0,[\"context\"]],[28,[37,1],null,[[\"split\"],[[50,\"validation/split-context\",0,null,[[\"context\"],[[30,0,[\"context\"]]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/components/validation/-group/part.hbs",
    "isStrictMode": false
  });
  // Should this have willDestroy, removing the created context?
  let ValidationGroupPart = _exports.default = (_dec = (0, _object.computed)('args.label', 'args.id'), _dec2 = (0, _object.computed)('contextID', 'args.parent', 'args.label', 'args.id'), _class = class ValidationGroupPart extends _component2.default {
    get contextID() {
      // Label-derived is fine, unless the label changes
      return this.args.id ?? this.args.label.toString().replace(/\./g, '');
    }
    get context() {
      if (!this.args.parent.get(this.contextID)) {
        this.args.parent.addChild(this.contextID, this.args.label);
      }
      return this.args.parent.get(this.contextID);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this.args.parent) {
        const context = this.args.parent.get(this.contextID);
        this.args.parent.removeChild(this.contextID);
        this.args.parent.subcontexts.removeObject(context);
        context.destroy();
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "contextID", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "contextID"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "context", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "context"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ValidationGroupPart);
});