define("plutof/components/plutof-panel/inner", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="panel plutof-panel {{if @compact 'plutof-panel--compact'}}" ...attributes>
      <div class="panel-body plutof-panel-body {{if @highlight 'colored-panel-body'}} {{@bodyClass}}">
          {{yield}}
      </div>
  </div>
  
  */
  {
    "id": "Nb9TXcrH",
    "block": "[[[11,0],[16,0,[29,[\"panel plutof-panel \",[52,[30,1],\"plutof-panel--compact\"]]]],[17,2],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"panel-body plutof-panel-body \",[52,[30,3],\"colored-panel-body\"],\" \",[30,4]]]],[12],[1,\"\\n        \"],[18,5,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@compact\",\"&attrs\",\"@highlight\",\"@bodyClass\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "plutof/components/plutof-panel/inner.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});