define("plutof/components/plutof-panel/inner-form", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! XXX: These should be compact by default }}
  <div class="panel plutof-panel plutof-fade-in {{if @compact 'plutof-panel--compact'}}" ...attributes>
      <div class="plutof-panel__inner-panel-close-btn">
          {{#if @remove}}
              <button {{on "click" @remove}} class="plutof-close">
                  <span class={{icon "remove"}}></span>
              </button>
          {{/if}}
      </div>
  
      <div class="panel-body plutof-panel-body">
          {{yield}}
      </div>
  </div>
  
  */
  {
    "id": "Y2TN88Hg",
    "block": "[[[11,0],[16,0,[29,[\"panel plutof-panel plutof-fade-in \",[52,[30,1],\"plutof-panel--compact\"]]]],[17,2],[12],[1,\"\\n    \"],[10,0],[14,0,\"plutof-panel__inner-panel-close-btn\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"            \"],[11,\"button\"],[24,0,\"plutof-close\"],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n                \"],[10,1],[15,0,[28,[37,2],[\"remove\"],null]],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"panel-body plutof-panel-body\"],[12],[1,\"\\n        \"],[18,4,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@compact\",\"&attrs\",\"@remove\",\"&default\"],false,[\"if\",\"on\",\"icon\",\"yield\"]]",
    "moduleName": "plutof/components/plutof-panel/inner-form.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});