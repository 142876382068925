define("plutof/components/search/results-gallery", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/utils", "@ember-decorators/object", "ember-concurrency", "plutof/misc/config", "plutof/models/filerepository/file", "plutof/utils/structures"], function (_exports, _component, _object, _runloop, _utils, _object2, _emberConcurrency, _config, _file, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  // Filerepo search:
  // - Translates types, as we need to show them
  // - Includes type id with records
  // Other searches:
  // - Types are untranslated
  // - No type ID
  //
  // This whole shebang deals with this difference
  //
  // TODO: Clean this mess on backend
  const MEDIA_TYPES = {
    [_file.FileType.Sound]: 'icon-file-sound',
    [_file.FileType.Image]: 'icon-file-image',
    [_file.FileType.Video]: 'icon-file-video'
  };
  const MEDIA_TYPE_TO_ID = {
    'Image': _file.FileType.Image,
    'Audio': _file.FileType.Sound,
    'Video': _file.FileType.Video
  };
  function isMediaFile(file) {
    return file.typeID in MEDIA_TYPES || file.type in MEDIA_TYPE_TO_ID;
  }
  function icon(file) {
    const typeID = (0, _utils.isNone)(file.typeID) ? MEDIA_TYPE_TO_ID[file.type] : file.typeID;
    return MEDIA_TYPES[typeID];
  }
  class Chunk extends _object.default {}
  let ResultsGallery = (_dec = (0, _object.computed)('results'), _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec3 = (0, _object2.observes)('chunks.@each.fullyLoaded', 'mustLoad'), _class = class ResultsGallery extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "showModal", false);
      _defineProperty(this, "activeChunk", null);
      _defineProperty(this, "mustLoad", false);
      _initializerDefineProperty(this, "loadFooterData", _descriptor, this);
    }
    // used to trigger initial load

    init() {
      super.init(...arguments);
      this.set('throttleHandle', null);
    }
    didInsertElement(...args) {
      super.didInsertElement(...args);
      window.addEventListener('scroll', this.didScroll);
      window.addEventListener('keyup', this.keyUp);
    }
    willDestroyElement(...args) {
      super.willDestroyElement(...args);
      window.removeEventListener('scroll', this.didScroll);
      window.removeEventListener('keyup', this.keyUp);
    }
    get chunks() {
      const searchResults = this.results;
      if ((0, _utils.isEmpty)(searchResults)) {
        return null;
      }
      const galleryFiles = (0, _structures.concatMap)(record => {
        const files = record.get('files');
        if ((0, _utils.isNone)(files)) {
          return [];
        }
        return (0, _structures.makeArray)(files).filter(isMediaFile).map(file => {
          const type = record.typeID || MEDIA_TYPE_TO_ID[file.type];
          if (type === _file.FileType.Image && !file.large_link) {
            // Filerepo search doesn't have large link included :shrug:
            file.large_link = file.link;
          }
          return {
            record: record,
            image: file,
            icon: icon(file),
            type
          };
        });
      }, searchResults);
      const chunks = (0, _structures.chop)(_config.default.Common.PAGE_SIZE, galleryFiles).map((chunkFiles, chunkIdx) => {
        return Chunk.create({
          data: chunkFiles,
          isVisible: chunkIdx === 0
        });
      });
      return chunks;
    }
    setImage(entry) {
      const image = (0, _object.get)(entry, 'image');
      this.set('selectedEntry', entry);
      // XXX: Shouldn't be needed, but because gallery-overlay changes
      // it upstream on close, can't bind to selectedEntry.image
      //
      this.set('selectedImage', image);
      this.loadFooterData.perform(image.id);
    }
    didScroll() {
      if (!this.isActiveTab) {
        return;
      }
      if (this.getScrollOffsetPercentage() < 30) {
        this.set('throttleHandle', (0, _runloop.throttle)(this, this.loadMore, 100));
      } else {
        (0, _runloop.cancel)(this.throttleHandle);
      }
    }
    keyUp(event) {
      if (!this.selectedImage) {
        return;
      }
      if (event.key === 'ArrowRight') {
        this.nextImg();
      }
      if (event.key === 'ArrowLeft') {
        this.prevImg();
      }
    }
    getScrollOffsetPercentage() {
      const clientHeight = document.documentElement.clientHeight;
      const scrollHeight = document.documentElement.scrollHeight;
      const topOffset = window.pageYOffset;
      const bottomOffset = scrollHeight - clientHeight - topOffset;

      // bottom offset percentage
      return Math.round(bottomOffset / clientHeight * 100);
    }
    loadMore() {
      const chunks = this.chunks;
      const mustLoad = this.mustLoad;
      if ((0, _utils.isPresent)(chunks) && mustLoad) {
        const chunk = chunks.findBy('isVisible', false); // get the first chunk that is not visible

        if ((0, _utils.isPresent)(chunk)) {
          chunk.set('isVisible', true);
        }
      }
    }
    selectFile(entry, chunk) {
      this.setImage(entry);
      this.set('selectedChunk', chunk);
    }
    nextImg() {
      const chunk = this.selectedChunk;
      if (!chunk) {
        return;
      }
      const entries = chunk.get('data');
      const currentEntry = this.selectedEntry;
      const entryIndex = entries.indexOf(currentEntry);
      if (entryIndex < entries.length - 1) {
        // Next image in the current chunk
        this.setImage(entries[entryIndex + 1]);
      } else {
        // First image in the next chunk
        const chunks = this.chunks;
        const nextChunkIndex = (chunks.indexOf(chunk) + 1) % chunks.length;
        const nextChunk = chunks[nextChunkIndex];
        this.setImage(nextChunk.get('data.firstObject'));
        this.set('selectedChunk', nextChunk);
      }
    }
    prevImg() {
      const chunk = this.selectedChunk;
      if (!chunk) {
        return;
      }
      const currentEntry = this.selectedEntry;
      const entries = chunk.get('data');
      const entryIndex = entries.indexOf(currentEntry);
      if (entryIndex > 0) {
        // Prev image in the current chunk
        this.setImage(entries[entryIndex - 1]);
      } else {
        // Last image in the prev chunk
        const chunks = this.chunks;
        let prevChunkIndex = chunks.indexOf(chunk) - 1;
        if (prevChunkIndex < 0) {
          prevChunkIndex = chunks.length - 1;
        }
        const prevChunk = chunks[prevChunkIndex];
        this.setImage(prevChunk.get('data.lastObject'));
        this.set('selectedChunk', prevChunk);
      }
    }
    loadNext() {
      const clientHeight = document.documentElement.clientHeight;
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollPercentage = Math.round(clientHeight / scrollHeight * 100);
      if (scrollPercentage > 80 || this.getScrollOffsetPercentage() < 30) {
        (0, _runloop.debounce)(this, this.loadMore, 200);
      } else {
        (0, _runloop.cancel)((0, _runloop.debounce)());
      }

      // XXX since this action is triggered by loading images, we can assume we have images
      this.announceImages(true);
    }
    _showOnMap() {
      // Externally sent from gallery overlay
      const recordId = this.get('selectedEntry.record.recordId');

      // Close the modal
      this.set('selectedImage', null);
      this.showOnMap(recordId);
    }
    close() {
      this.set('selectedImage', null);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "chunks", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "chunks"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "loadFooterData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (fileID) {
        const file = yield this.store.findRecord('filerepository/file', fileID);
        return file;
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didScroll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "keyUp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "keyUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectFile", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextImg", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "nextImg"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "prevImg", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "prevImg"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadNext", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadNext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_showOnMap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_showOnMap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _class);
  var _default = _exports.default = ResultsGallery;
});