define("plutof/components/dmp/dmp-identifiers", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/service", "@ember/utils", "plutof/misc/options-getter"], function (_exports, _component, _templateFactory, _object, _service, _utils, _optionsGetter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    
  {{!-- *** *** ***
      Not doing that. TODO: Delete me.
  --}}
  
  <AddMultipleForm @objects={{this.dmp.ids}} as |adder|>
      <div class="add-component">
          <adder.column @path="dmp.dmpID.identifier">
              <Input
                  @type="text"
                  @value={{this.newIdentifier}}
                  @enter={{this.add}}
                  @class="form-control control" />
          </adder.column>
  
          <adder.column @path="dmp.dmpID.type">
              <PlutofSelect::Value
                  @content={{this.idTypes}}
                  @value={{this.newType}}
                  @selectClass="form-control control" />
          </adder.column>
  
          <adder.addButton @add={{this.add}} @disable={{this.disableAddButton}} />
      </div>
  
      <Common::RecordList @records={{@dmp.ids}} @remove={{adder.remove}} as |record|>
          {{record.identifier}} ({{record.type}})
      </Common::RecordList>
  </AddMultipleForm>
  
  */
  {
    "id": "w/3ihI9s",
    "block": "[[[1,\"\\n\"],[1,\"\\n\"],[8,[39,0],null,[[\"@objects\"],[[30,0,[\"dmp\",\"ids\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"add-component\"],[12],[1,\"\\n        \"],[8,[30,1,[\"column\"]],null,[[\"@path\"],[\"dmp.dmpID.identifier\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,1],null,[[\"@type\",\"@value\",\"@enter\",\"@class\"],[\"text\",[30,0,[\"newIdentifier\"]],[30,0,[\"add\"]],\"form-control control\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1,[\"column\"]],null,[[\"@path\"],[\"dmp.dmpID.type\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,2],null,[[\"@content\",\"@value\",\"@selectClass\"],[[30,0,[\"idTypes\"]],[30,0,[\"newType\"]],\"form-control control\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1,[\"addButton\"]],null,[[\"@add\",\"@disable\"],[[30,0,[\"add\"]],[30,0,[\"disableAddButton\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@records\",\"@remove\"],[[30,2,[\"ids\"]],[30,1,[\"remove\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"identifier\"]]],[1,\" (\"],[1,[30,3,[\"type\"]]],[1,\")\\n    \"]],[3]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"adder\",\"@dmp\",\"record\"],false,[\"add-multiple-form\",\"input\",\"plutof-select/value\",\"common/record-list\"]]",
    "moduleName": "plutof/components/dmp/dmp-identifiers.hbs",
    "isStrictMode": false
  });
  let DMPIdentifiers = (_dec = (0, _optionsGetter.getDMPChoices)('ids.items.properties.type'), _dec2 = (0, _object.computed)('newIdentifier', 'newType'), _class = class DMPIdentifiers extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "newIdentifier", '');
      _defineProperty(this, "newType", null);
      _initializerDefineProperty(this, "idTypes", _descriptor2, this);
    }
    get disableAddButton() {
      return (0, _utils.isEmpty)(this.newIdentifier.trim()) || (0, _utils.isEmpty)(this.newType);
    }
    add() {
      this.dmp.ids.pushObject({
        identifier: this.newIdentifier,
        type: this.newType
      });
      this.setProperties({
        newIdentifier: ''
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "idTypes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "disableAddButton", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "disableAddButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DMPIdentifiers);
});