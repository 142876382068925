define("plutof/utils/changeset", ["exports", "ember-changeset", "@glimmer/tracking", "@ember/object"], function (_exports, _emberChangeset, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validatedChangeset = validatedChangeset;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // What kind of tomfoolery is going on here, you ask?
  //
  // So, ember-changeset has a support for validations. Which is great, because we
  // need those, and our usual solution doesn't work here, as it is tied to components
  // and so its state is lost when the page is switched. And before you ask why not
  // bundle each item with its own context or disable pagination if the current page
  // is invaldi - I've been there, it's all flawed and whatever seems like it's not
  // just fails in practice, its brittle dependency chains broken somewhere in ember
  // bowels. Anyway, I want this to be a good user experience, so we need a proper
  // validation.
  //
  // The interface is a bit weird, there are two distinct arguments, they are barely
  // mentioned in the docs, because (as far as I understand) they are there solely
  // for the purposes on another library, which adds a sort of validation eDSL on top
  // of changesets. Which is noble, but we don't really need that (and in any case it
  // doesn't deal with what you're about to read).
  //
  // And validations work. They just have a teeny tiny problem: they are run only when
  // something changes. They validate not the record state, but the changes to it. Which
  // is fine and makes sense and lets you optimize stuff a bit, but the empty Association
  // is invalid and we want to show it (and thread this invalid state topside).
  //
  // Well, what's the problem, Fil. Surely even a filthy frontend dev like yourself can
  // think about a couple of workarounds for this? You move me aside and start typing
  //
  // A cursory looks at the API page reveals a validate() method
  //
  //      changeset.validate()
  //
  // Voila, we're done! Except, nothing actually validates. Remember that there are
  // two validation arguments. First one is what all changes go through. The second is
  // a map fieldName -> validation and it's used exclusively by calls to .validate.
  // Oh well, interface design is not easy, we can forgive the unpaid FOSS devs.
  // You quickly whip up the validation map, cleverly encapsulating stuff so that
  // the primary validator is generate from it. "DRY means Dont Repeat Yourself" you
  // explain to me.
  //
  // Only nothing validates anymore at all. You squint into screen, trying to find out
  // what is missing in, like, four lines of code. Nothing is missing. It just doesn't
  // work.
  //
  // Time to whip up the debugger. It shows you that .validate is running 0 validations,
  // because somehow it doesn't see that your map is not empty. But, a better solution
  // is revealed - .validate can take a list of fields! Because it is called on
  // pagination.add, there is some plumbing involded in threading the list of fields
  // there, but this is a serious dev establishment.
  //
  // Great, the item starts validated, the row red. You look at me triuphantly, having
  // asserted the dominance. Was that so hard? I clap and invite you to demonstrate to
  // me the superior validation logic, show me how beautifully the state changes.
  //
  // "Hm, that's weird". Somehow, the item doesn't go back to failed state when it
  // should. Nearby, I struggle with nervous ticks of traumatic familiarity with
  // what's happening.
  //
  // After half an hour, you diff the problem: the validations break because of that
  // initial .validate call. Well, whatever bugs that library has, you should be able
  // to work around that. .validate gets commented out, and you reach out to the docs
  // once again.
  //
  // Why, there is a whole bounty of possible solutions in the public interface alone!
  //
  // .addError registers a failed field. It also break validations
  // .pushErrors registers multiple fails, with optimizations. The error persists
  //
  // You start realising the trap you've fallen into. It's day three and it's getting
  // harder to explait what is it that you're doing the whole day at the daily meeting.
  // Time to bring out the big guns, you're going full-spectrum tracing. Rookie mistake.
  //
  // You trace both failing and working code, down to the ember runtime. It's got some
  // complex part for handling async validations and such, but overall it's a pretty
  // simple logic. And yet it doesn't work. You finally narrow it down to one line
  //
  //      set(ERRORS, field, validation)
  //
  // The debugger shows that nothing is set. Somehow the ember set just fails on this.
  // You ask me, if I'm seeing this. I am. I've seen it before, and now you're validating
  // my madness. The basic rules of reality are broken. And tracing them revealse nothing,
  // because the ember runtime is a simple interface on top a writhing, hyper-optimized
  // (and still failing every benchmark somehow) monstrocity of a code. There is no wisdom
  // to be extracted from it.
  //
  // You try some solutions more. Nothing works. How can software be grown when the substrate
  // cannot be trusted? You damn the smooth-tongued Katz and the day he sold his stack
  // to your team.
  //
  // In the end, there are no solutions. There are only weird workaround, one of which
  // finally works by basically tracking validations separately. It will look to anybody
  // like stupid and unnecessary. They will try to refactor it, they will point to the
  // library docs with their syllogisms and proper solutions. The madness will spread.
  //
  // You start chiseling a warning...
  let WorkingChangeset = (_dec = (0, _object.computed)('_activeErrors.[]'), _dec2 = (0, _object.computed)('_activeErrors.[]'), _class = class WorkingChangeset extends _emberChangeset.EmberChangeset {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_activeErrors", _descriptor, this);
    }
    get fieldErrors() {
      let errors = {};
      for (const field of this._activeErrors) {
        errors[field] = true;
      }
      return errors;
    }
    get workingIsValid() {
      return this._activeErrors.length === 0;
    }
    addError(field, validation) {
      return super.addError(field, validation);
    }
    _plutofHandleError(field, valid) {
      if (valid) {
        this._activeErrors.removeObject(field);
      } else if (!this.fieldErrors[field]) {
        this._activeErrors.pushObject(field);
      }
    }
    _handleValidation(...args) {
      const ret = super._handleValidation(...args);
      const [valid, {
        key
      }] = args;
      this._plutofHandleError(key, valid);
      return ret;
    }

    // Same as super._validate, the only difference is passing .changeset
    // to validator
    _validate(key, newValue, oldValue) {
      const validator = this._validator;
      const content = this._content;
      if (validator) {
        const result = validator({
          key,
          newValue,
          oldValue,
          changes: this.change,
          content,
          changeset: this
        });

        // Not found
        if (result === undefined) {
          return true;
        }
        return result;
      }
      return true;
    }
    deleteRecord() {
      return this._content.deleteRecord();
    }
    unloadRecord() {
      return this._content.unloadRecord();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_activeErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fieldErrors", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "fieldErrors"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "workingIsValid", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "workingIsValid"), _class.prototype), _class); // NB: Validators MUST return a boolean
  // compoundValidations :: [{ id: String, fields: [String],  validator: (changeset} => Bool}]
  function validatedChangeset(fieldValidations, compoundValidations = []) {
    function validator({
      key,
      newValue,
      changeset
    }) {
      const handler = fieldValidations[key];
      for (const compound of compoundValidations) {
        if (compound.fields.includes(key)) {
          changeset._plutofHandleError(compound.id, compound.validator(changeset));
        }
      }
      if (handler) {
        return handler(newValue, changeset);
      }
    }
    function initialValidate(changeset) {
      for (const key of Object.keys(fieldValidations)) {
        if (!fieldValidations[key](changeset[key], changeset)) {
          changeset._plutofHandleError(key, false);
        }
      }
      for (const compound of compoundValidations) {
        changeset._plutofHandleError(compound.id, compound.validator(changeset));
      }
    }
    return function (record, {
      validate = true
    } = {}) {
      const changeset = (0, _emberChangeset.Changeset)(record, validator, null, {
        changeset: WorkingChangeset
      });
      changeset.compoundValidations = compoundValidations;
      delete changeset._changes['compoundValidations'];
      if (validate) {
        initialValidate(changeset);
      }
      return changeset;
    };
  }
});