define("plutof/components/dmp/view/metadata", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Inner @compact={{true}}>
      <Layout::RegularGrid @columns={{2}}>
          <GuidedView @path="dmp.datasetMetadata.language">
              {{@metadata.language}}
          </GuidedView>
  
          <GuidedView @path="dmp.datasetMetadata.description">
              {{@metadata.description}}
          </GuidedView>
  
          <GuidedView @path="dmp.datasetMetadataStandard.identifier">
              {{@metadata.metadata_standard_id.identifier}}
          </GuidedView>
  
          <GuidedView @path="dmp.datasetMetadataStandard.type">
              {{@metadata.metadata_standard_id.type}}
          </GuidedView>
      </Layout::RegularGrid>
  </PlutofPanel::Inner>
  
  */
  {
    "id": "N6VzTKqs",
    "block": "[[[8,[39,0],null,[[\"@compact\"],[true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@path\"],[\"dmp.datasetMetadata.language\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"language\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,2],null,[[\"@path\"],[\"dmp.datasetMetadata.description\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"description\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,2],null,[[\"@path\"],[\"dmp.datasetMetadataStandard.identifier\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"metadata_standard_id\",\"identifier\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,2],null,[[\"@path\"],[\"dmp.datasetMetadataStandard.type\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"metadata_standard_id\",\"type\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@metadata\"],false,[\"plutof-panel/inner\",\"layout/regular-grid\",\"guided-view\"]]",
    "moduleName": "plutof/components/dmp/view/metadata.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});