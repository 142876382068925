define("plutof/components/dmp/view/general", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "dmp.panels.general"}}>
      <Layout::RegularGrid @columns={{2}} @cellGap={{true}}>
          <GuidedView @path="dmp.fields.title">
              {{@dmp.title}}
          </GuidedView>
  
          <GuidedView @path="dmp.fields.identifier">
              {{@dmp.dmp_id.identifier}}
          </GuidedView>
  
          <GuidedView @path="dmp.fields.identifierType">
              <Dmp::View::Choice
                  @property="ids.items.properties.type"
                  @value={{@dmp.dmp_id.type}} />
          </GuidedView>
  
          <GuidedView @path="dmp.fields.ethical_issues_exist">
              {{! XXX: OPTIONS has it as a boolean, but it's a controlled vocab }}
              {{@dmp.ethical_issues_exist}}
              {{!-- <Dmp::View::Choice
                  @property="ids.items.properties.type"
                  @value={{@dmp.dmp_id.type}} /> --}}
          </GuidedView>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "KNwtwk3M",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"dmp.panels.general\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\",\"@cellGap\"],[2,true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.fields.title\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"title\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.fields.identifier\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"dmp_id\",\"identifier\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.fields.identifierType\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,4],null,[[\"@property\",\"@value\"],[\"ids.items.properties.type\",[30,1,[\"dmp_id\",\"type\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.fields.ethical_issues_exist\"]],[[\"default\"],[[[[1,\"\\n\"],[1,\"            \"],[1,[30,1,[\"ethical_issues_exist\"]]],[1,\"\\n\"],[1,\"        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@dmp\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"guided-view\",\"dmp/view/choice\"]]",
    "moduleName": "plutof/components/dmp/view/general.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});