define("plutof/components/dmp/dataset-metadata", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object/computed", "@ember/service", "plutof/misc/options-getter", "plutof/utils/validations"], function (_exports, _component, _templateFactory, _component2, _computed, _service, _optionsGetter, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::InnerForm @remove={{@remove}} @compact={{true}}>
      <Layout::RegularGrid @columns={{2}} @cellGap={{true}}>
          <GuidedForm @path="dmp.datasetMetadata.language">
              <PlutofSelect::Direct
                  @content={{array "eng" "est"}}
                  @value={{@metadata.language}}
                  @prompt={{null}}
                  @placeholder={{i18n-t "dmp.dataset.language.placeholder"}} />
          </GuidedForm>
  
          <GuidedForm @path="dmp.datasetMetadata.description">
              <FlexibleTextarea @value={{@metadata.description}} @class="form-control" />
          </GuidedForm>
  
          <GuidedForm
              @path="dmp.datasetMetadataStandard.identifier"
              class={{unless this.identifierValid 'has-error'}}
          >
              <Input
                  @type="text"
                  @value={{@metadata.metadata_standard_id.identifier}}
                  @placeholder={{i18n-t "dmp.datasetMetadataStandard.identifier.placeholder"}}
                  @class="form-control" />
          </GuidedForm>
  
          <GuidedForm
              @path="dmp.datasetMetadataStandard.type"
              class={{unless this.identifierTypeValid 'has-error'}}
          >
              <PlutofSelect::Value
                  @content={{this.metadataStandardTypes}}
                  @value={{@metadata.metadata_standard_id.type}}
                  @prompt={{null}} />
          </GuidedForm>
      </Layout::RegularGrid>
  </PlutofPanel::InnerForm>
  
  */
  {
    "id": "S9Ur5R+8",
    "block": "[[[8,[39,0],null,[[\"@remove\",\"@compact\"],[[30,1],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@columns\",\"@cellGap\"],[2,true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@path\"],[\"dmp.datasetMetadata.language\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,3],null,[[\"@content\",\"@value\",\"@prompt\",\"@placeholder\"],[[28,[37,4],[\"eng\",\"est\"],null],[30,2,[\"language\"]],null,[28,[37,5],[\"dmp.dataset.language.placeholder\"],null]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,2],null,[[\"@path\"],[\"dmp.datasetMetadata.description\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],null,[[\"@value\",\"@class\"],[[30,2,[\"description\"]],\"form-control\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,2],[[16,0,[52,[51,[30,0,[\"identifierValid\"]]],\"has-error\"]]],[[\"@path\"],[\"dmp.datasetMetadataStandard.identifier\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,8],null,[[\"@type\",\"@value\",\"@placeholder\",\"@class\"],[\"text\",[30,2,[\"metadata_standard_id\",\"identifier\"]],[28,[37,5],[\"dmp.datasetMetadataStandard.identifier.placeholder\"],null],\"form-control\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,2],[[16,0,[52,[51,[30,0,[\"identifierTypeValid\"]]],\"has-error\"]]],[[\"@path\"],[\"dmp.datasetMetadataStandard.type\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,9],null,[[\"@content\",\"@value\",\"@prompt\"],[[30,0,[\"metadataStandardTypes\"]],[30,2,[\"metadata_standard_id\",\"type\"]],null]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@remove\",\"@metadata\"],false,[\"plutof-panel/inner-form\",\"layout/regular-grid\",\"guided-form\",\"plutof-select/direct\",\"array\",\"i18n-t\",\"flexible-textarea\",\"unless\",\"input\",\"plutof-select/value\"]]",
    "moduleName": "plutof/components/dmp/dataset-metadata.hbs",
    "isStrictMode": false
  });
  let DMPDatasetMetadata = (_dec = (0, _optionsGetter.getDMPChoices)('dataset.properties.metadata.properties.metadata_standard_id.properties.type'), _dec2 = (0, _computed.notEmpty)('args.metadata.metadata_standard_id.identifier'), _dec3 = (0, _computed.notEmpty)('args.metadata.metadata_standard_id.type'), _class = class DMPDatasetMetadata extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "metadataStandardTypes", _descriptor2, this);
      _initializerDefineProperty(this, "identifierValid", _descriptor3, this);
      _initializerDefineProperty(this, "identifierTypeValid", _descriptor4, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metadataStandardTypes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "identifierValid", [_validations.validator, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "identifierTypeValid", [_validations.validator, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DMPDatasetMetadata);
});