define("plutof/components/dmp/dataset-technical-resource", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AddMultipleForm @objects={{@dataset.technical_resource}} as |adder|>
      <div class="add-component">
          <adder.column @path="dmp.datasetTechnicalResource.name"}}>
              <Input @type="text" @value={{this.newName}} @class="form-control control" />
          </adder.column>
  
          <adder.column @path="dmp.datasetTechnicalResource.description"}}>
              <Input @type="text" @value={{this.newDescription}} @class="form-control control" />
          </adder.column>
  
          <adder.addButton @add={{this.add}} @disable={{this.disableAddButton}} />
      </div>
  
      <Common::RecordList @records={{@dataset.technical_resource}} @remove={{adder.remove}} as |record|>
          {{record.name}} ({{record.description}})
      </Common::RecordList>
  </AddMultipleForm>
  
  */
  {
    "id": "AHoMw0ml",
    "block": "[[[8,[39,0],null,[[\"@objects\"],[[30,1,[\"technical_resource\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"add-component\"],[12],[1,\"\\n        \"],[8,[30,2,[\"column\"]],[[24,\"}}\",\"\"]],[[\"@path\"],[\"dmp.datasetTechnicalResource.name\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,1],null,[[\"@type\",\"@value\",\"@class\"],[\"text\",[30,0,[\"newName\"]],\"form-control control\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2,[\"column\"]],[[24,\"}}\",\"\"]],[[\"@path\"],[\"dmp.datasetTechnicalResource.description\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,1],null,[[\"@type\",\"@value\",\"@class\"],[\"text\",[30,0,[\"newDescription\"]],\"form-control control\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2,[\"addButton\"]],null,[[\"@add\",\"@disable\"],[[30,0,[\"add\"]],[30,0,[\"disableAddButton\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@records\",\"@remove\"],[[30,1,[\"technical_resource\"]],[30,2,[\"remove\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"name\"]]],[1,\" (\"],[1,[30,3,[\"description\"]]],[1,\")\\n    \"]],[3]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@dataset\",\"adder\",\"record\"],false,[\"add-multiple-form\",\"input\",\"common/record-list\"]]",
    "moduleName": "plutof/components/dmp/dataset-technical-resource.hbs",
    "isStrictMode": false
  });
  let DMPDatasetTechnicalResource = (_dec = (0, _object.computed)('newName', 'newDescription'), _class = class DMPDatasetTechnicalResource extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "newName", '');
      _defineProperty(this, "newDescription", '');
    }
    get disableAddButton() {
      return (0, _utils.isEmpty)(this.newName.trim()) || (0, _utils.isEmpty)(this.newDescription.trim());
    }
    add() {
      this.dataset.technical_resource.pushObject({
        name: this.newName,
        description: this.newDescription
      });
      this.setProperties({
        newName: '',
        newDescription: ''
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "disableAddButton", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "disableAddButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DMPDatasetTechnicalResource);
});